* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  list-style: none;
  quotes: none;
  font-style: normal;
  outline: none;
  text-decoration: none;
}

*:focus {
  outline: none;
}

body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  border: 0;
  background: #fff;
  font-size: 16px;
  font-family: "Albra";
  position: relative;
  min-width: 370px;
  font-family: "Roboto", sans-serif;
}

button {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 1em;
}

@font-face {
  font-family: "Albra";
  font-style: normal;
  font-weight: normal;
  src: local("Albra"), url(./assets/fonts/Albra.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  src: local("Raleway-Light"),
    url(./assets/fonts/Raleway-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: local("Lato-Regular"),
    url(./assets/fonts/Lato-Regular.ttf) format("truetype");
}
