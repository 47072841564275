.legal {
  min-height: 100vh;
  padding-top: 4em;
}

.wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.46);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4em;
  background: #000;
}

.wrapper > div {
  width: 100%;
}

.info {
  padding: 4em;
  background: #fff;
}

.info > h4 {
  padding: 1em 0;
}
.info > h4:first-child {
  padding: 0 0 1em 0;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.logoContainer > img {
  width: 50%;
}
